import {Component, inject} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from '@angular/router';
import {ColorPickerComponent} from "./libraries/color-picker/color-picker.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ColorPickerComponent, RouterLink],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  router = inject(Router);

  onComponentsClicked() {
    this.router.navigate(['/components']);
  }
}
