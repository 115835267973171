<div class="container-fluid mx-auto py-8 px-4 h-dvh flex flex-col items-center justify-center bg-cover bg-no-repeat">
  <div class="absolute h-full w-full bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)]"></div>
  <div class="z-50 flex flex-col justify-center items-center gap-8">
    <div class="flex flex-col justify-center items-center">
      <p class="text-h3 font-normal text-neutral-700 text-center">Collection of modern</p>
      <p class="text-h3 text-neutral-500 text-center">User Interface</p>
    </div>

    <p class="text-body1 max-w-2xl text-center">Ready-to-use, Angular-based component library, designed to streamline
      and enhance your UI/UX solutions effortlessly.</p>

    <div (click)="onComponentsClicked()" class="flex items-center justify-center gap-2 px-3 py-2 bg-neutral-950 text-white text-center rounded cursor-pointer select-none">
      <p class="text-body2">Components</p>
      <svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M3.99997 13L3.99997 11L16 11L10.5 5.5L11.92 4.08L19.84 12L11.92 19.92L10.5 18.5L16 13L3.99997 13Z"
              fill="currentColor"/>
      </svg>
    </div>

  </div>
</div>
