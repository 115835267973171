import {Directive, ElementRef, EventEmitter, HostListener, inject, Input, Output} from '@angular/core';

@Directive({
  selector: '[colorPickerSlider]',
  standalone: true
})
export class ColorPickerSliderDirective {
  private readonly listenerMove: any;
  private readonly listenerStop: any;

  @Input() rgX!: number;
  @Input() rgY!: number;

  @Output() dragEnd = new EventEmitter();
  @Output() dragStart = new EventEmitter();

  @Output() valueChanged = new EventEmitter<any>();

  elementRef = inject(ElementRef);

  @HostListener('mousedown', ['$event']) mouseDown(event: MouseEvent): void {
    this.start(event);
  }

  @HostListener('touchstart', ['$event']) touchStart(event: MouseEvent): void {
    this.start(event);
  }

  constructor() {
    this.listenerMove = (event: MouseEvent) => this.move(event);
    this.listenerStop = () => this.stop();
  }

  private move(event: any): void {
    event.preventDefault();
    this.setCursor(event);
  }

  private start(event: MouseEvent): void {
    this.setCursor(event);

    event.stopPropagation();

    document.addEventListener('mouseup', this.listenerStop);
    document.addEventListener('touchend', this.listenerStop);
    document.addEventListener('mousemove', this.listenerMove);
    document.addEventListener('touchmove', this.listenerMove);

    this.dragStart.emit();
  }

  private stop(): void {
    document.removeEventListener('mouseup', this.listenerStop);
    document.removeEventListener('touchend', this.listenerStop);
    document.removeEventListener('mousemove', this.listenerMove);
    document.removeEventListener('touchmove', this.listenerMove);

    this.dragEnd.emit();
  }

  private getXAxis(event: any): number {
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    const pageX = (event.pageX !== undefined) ? event.pageX : event.touches[0].pageX;
    return pageX - position.left - window.pageXOffset;
  }

  private getYAxis(event: any): number {
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    const pageY = (event.pageY !== undefined) ? event.pageY : event.touches[0].pageY;
    return pageY - position.top - window.pageXOffset;
  }

  private setCursor(event: MouseEvent): void {
    const width = this.elementRef.nativeElement.offsetWidth;
    const height = this.elementRef.nativeElement.offsetHeight;

    const x = Math.max(0, Math.min(this.getXAxis(event), width));
    const y = Math.max(0, Math.min(this.getYAxis(event), height));

    if (this.rgX !== undefined && this.rgY !== undefined) {
      this.valueChanged.emit({x: x, y: y, s: x / width, v: (1 - y / height), rgX: this.rgX, rgY: this.rgY});
    } else if (this.rgX === undefined && this.rgY !== undefined) {
      this.valueChanged.emit({x: x, y: y, v: y / height, rgY: this.rgY});
    } else if (this.rgX !== undefined && this.rgY === undefined) {
      this.valueChanged.emit({x: x, y: y, v: x / width, rgX: this.rgX});
    }
  }

}
