import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, computed,
  ElementRef,
  EventEmitter, HostListener,
  inject,
  input,
  Input,
  model,
  OnChanges,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {Hsva} from "./utils/formats";
import {ConverterService} from "./converter.service";
import {SliderDimension, SliderPosition} from "./utils/slider";
import {ColorFormats} from "./enums/formats";
import {ColorPickerSliderDirective} from "./color-picker-slider.directive";
import {NgClass, NgStyle} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-color-picker',
  standalone: true,
  imports: [
    ColorPickerSliderDirective,
    NgStyle,
    FormsModule,
    NgClass
  ],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss'
})
export class ColorPickerComponent implements OnInit, AfterViewInit, OnChanges {
  hexColor = input('#00FF00');
  @Input() controls: "default" | "only-alpha" | "no-alpha" = "default";
  @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>(false);
  @Output() onAlphaChange: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() closeColorPicker = new EventEmitter();

  color = new Hsva(0, 1, 1, 1);

  COLOR_PICKER_HEIGHT = signal(140);
  COLOR_PICKER_WIDTH = signal(250);

  converterService = inject(ConverterService);
  cdr = inject(ChangeDetectorRef);

  hsva = signal<Hsva>(new Hsva(0, 1, 1, 1));
  private outputColor!: Hsva;
  selectedColor = signal("#000000");

  private sliderDimMax = signal<SliderDimension>(new SliderDimension(0, 1, 1, 1));
  public slider = signal<SliderPosition>(new SliderPosition(0, 1, 1, 1));

  hueSliderColor = signal<string | null>(null);
  alphaSliderColor = signal<string | null>(null);

  @ViewChild("hueSlider", {static: false}) hueSlider!: ElementRef;
  @ViewChild("alphaSlider", {static: false}) alphaSlider!: ElementRef;

  height= computed(() => {
    return `${this.COLOR_PICKER_HEIGHT()}px`;
  });

  width= computed(() => {
    return `${this.COLOR_PICKER_WIDTH()}px`;
  });
  // height = signal(`${this.COLOR_PICKER_HEIGHT()}px`);
  // width = signal(`${this.COLOR_PICKER_WIDTH()}px`);
  isTooltip = signal(false);

  public format: ColorFormats = ColorFormats.HEX;
  hexColorValue = model('');

  @HostListener('document:mousedown', ['$event'])
  click(event: MouseEvent) {
    if (this.isOutside(event)) {
      // this.emitClose('cancel');
      this.closeColorPicker.emit();
      console.log('Clicked outside');
    }
  }

  isOutside(event: any) {
    return event.target.classList.contains('ng-color-picker');
  }

  ngOnInit(): void {
    this.setHexColor(this.hexColor());
  }

  ngOnChanges(changes: any): void {
    this.setHexColor(changes.hexColor.currentValue);
  }

  setHexColor(hexColor: string) {
    if (hexColor != null && hexColor != '') {
      const hexToHsva = this.converterService.stringToHsva(hexColor);
      if (hexToHsva) {
        this.color = hexToHsva;
        this.hexColorValue.set(hexColor);
      }
    }

    if (this.color) {
      this.slider.set(new SliderPosition(this.color.h, this.color.s, this.color.v, this.color.a));
      this.update();
    } else {
      this.slider.set(new SliderPosition(0, 0, 0, 0));
      this.update();
    }
  }

  ngAfterViewInit(): void {
    const hueWidth = this.hueSlider?.nativeElement.offsetWidth || 140;
    const alphaWidth = this.alphaSlider?.nativeElement.offsetWidth || 140;
    const sliderDimensionMax = new SliderDimension(hueWidth, this.COLOR_PICKER_WIDTH(), this.COLOR_PICKER_HEIGHT(), alphaWidth);
    this.sliderDimMax.set(sliderDimensionMax);
    this.update();
  }

  public onSliderChange(type: string, event: any) {
    switch (type) {
      case 'saturation-lightness':
        this.hsva().onColorChange(event);
        break;
      case 'hue':
        this.hsva().onHueChange(event);
        break;
      case 'alpha':
        this.hsva().onAlphaChange(event);
        this.onAlphaChange.emit(event);
        break;
      case 'value':
        this.hsva().onValueChange(event);
        break;
    }
    this.update();
    this.setColor(this.outputColor);
    this.hexColorValue.set(this.converterService.toFormat(this.hsva(), ColorFormats.HEX));
  }

  setColor(color: Hsva) {
    this.color = color;
    if (!this.isTooltip()) {
      this.colorChanged.emit(this.converterService.toFormat(this.color, ColorFormats.HEX));
    }
  }

  public getBackgroundColor(color: any) {
    return {
      background:
        "linear-gradient(90deg, rgba(36,0,0,0) 0%, " + color + " 100%)",
    };
  }

  private update() {
    this.hsva.set(this.color);
    if (this.sliderDimMax) {
      let rgba = this.converterService.hsvaToRgba(this.hsva()).denormalize();
      let hue = this.converterService
        .hsvaToRgba(new Hsva(this.hsva().h, 1, 1, 1))
        .denormalize();

      this.hueSliderColor.set("rgb(" + hue.r + "," + hue.g + "," + hue.b + ")");
      this.alphaSliderColor.set("rgb(" + rgba.r + "," + rgba.g + "," + rgba.b + ")");

      this.outputColor = this.hsva();
      this.selectedColor.set(this.converterService.hsvaToRgba(this.hsva()).toString());

      const sliderPosition = new SliderPosition(
        // (this.sHue || this.hsva.h) * this.sliderDimMax.h - 8,
        this.hsva().h * this.sliderDimMax().h - 5,
        this.hsva().s * this.sliderDimMax().s,
        (1 - this.hsva().v) * this.sliderDimMax().v,
        this.hsva().a * this.sliderDimMax().a - 5
      );
      this.slider.set(sliderPosition);
      this.cdr.detectChanges();
    }
  }

  onHexColorChange(event: any) {
    const hexCode = event.target.value.toString().trim();
    if (this.converterService.isValidHexColor(hexCode)) {
      this.setHexColor(this.converterService.addHexPrefixIfMissing(hexCode));
    }
  }

  onApplyCLicked() {
    this.colorChanged.emit(this.converterService.toFormat(this.color, ColorFormats.HEX));
  }
}
