<div [ngClass]="isTooltip() ? 'shadow-1 bg-white' : ''">

  <div [style.width]="width()">

    <!--Saturation and Lightness-->
    <div
      class="sl"
      [style.height]="height()"
      [style.width]="width()"
      colorPickerSlider
      [rgX]="1"
      [rgY]="1"
      (valueChanged)="onSliderChange('saturation-lightness', $event)"
      [style.background-color]="hueSliderColor()">

      <!--Saturation & Lightness pointer-->
      <div
        class="sl-pointer"
        [style.left.px]="slider().s"
        [style.top.px]="slider().v">
      </div>

    </div>

    <div class="flex flex-col gap-4 mt-4" [ngClass]="isTooltip() ? 'p-4' : ''">
      <!--Selected Color-->
      <div class="flex gap-5 items-center">
        <div class="relative">
          <div class="selected-color-background"></div>
          <div
            class="h-10 w-10 rounded-xl selected-color"
            [style.background-color]="selectedColor()">
          </div>
        </div>


        <div class="flex flex-col w-full gap-4">
          <div #hueSlider
               class="hue"
               colorPickerSlider
               (valueChanged)="onSliderChange('hue', $event)"
               [rgX]="1">
            <div class="hue-pointer"
                 [style.left.px]="slider().h"></div>
          </div>
          <div #alphaSlider
               class="alpha"
               colorPickerSlider
               [rgX]="1"
               (valueChanged)="onSliderChange('alpha', $event)">
            <div class="alpha-gradient" [ngStyle]="getBackgroundColor(alphaSliderColor())"></div>
            <div class="alpha-pointer"
                 [style.left.px]="slider().a"></div>
          </div>
        </div>

      </div>

      <div class="flex items-center gap-3">
        <div class="relative rounded w-min flex items-center focus-within:text-neutral-600">
          <p class="absolute text-sm text-blue-500 px-3 pointer-events-none">HEX</p>
          <input [ngModel]="hexColorValue()" (input)="onHexColorChange($event)"
                 class="py-2 pl-12 pr-3 w-36 bg-gray-100 focus:outline-none rounded focus:ring-gray-200 focus:ring-2">
        </div>
        @if (isTooltip()) {
          <div class="px-3 py-2 border border-neutral-300 h-min rounded-md cursor-pointer text-primary-500" (click)="onApplyCLicked()">Apply</div>
        }
      </div>
    </div>

  </div>


</div>



