import {Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ComponentsComponent} from "./pages/components/components.component";
import {LandingPageComponent} from "./pages/landing-page/landing-page.component";

export const routes: Routes = [
  {path: '', component: LandingPageComponent},
  {
    path: '', component: HomeComponent, children: [
      {path: 'components', component: ComponentsComponent},
    ]
  },
];
