import {Component, HostListener} from '@angular/core';
import {ColorPickerComponent} from "../../libraries/color-picker/color-picker.component";
import {TooltipDirective} from "../../libraries/tooltip/tooltip.directive";
import {TooltipPosition, TooltipTheme} from "../../libraries/tooltip/tooltip.enums";
import {NgIf} from "@angular/common";
import {ColorPickerDirective} from "../../libraries/color-picker/color-picker.directive";

@Component({
  selector: 'app-components',
  standalone: true,
  imports: [
    ColorPickerComponent,
    TooltipDirective,
    NgIf,
    ColorPickerDirective
  ],
  templateUrl: './components.component.html',
  styleUrl: './components.component.scss'
})
export class ComponentsComponent {

  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  x = 0;
  y = 0;
  coordinates = "";

  @HostListener('mousemove', ['$event'])
  onMouseMove($event: MouseEvent): void {
    this.x = $event.clientX;
    this.y = $event.clientY;
    this.coordinates = `${this.x},${this.y}`;
  }

  protected readonly TooltipTheme = TooltipTheme;

  showTooltip = true;
}
