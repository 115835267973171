<div class="container-fluid h-dvh flex">
  <div class="w-64 h-dvh p-6 border-r border-neutral-200">
    <p class="text-h5 font-bold text-neutral-700 cursor-pointer" routerLink="/">Lift NG UI</p>
    <div class="my-10"></div>
    <div class="flex flex-col gap-4">

      <p class="title text-body1 text-primary-700 cursor-pointer">Get Started</p>
      <p class="sections text-body2 text-neutral-500 cursor-pointer">Installation</p>
      <p class="sections text-body2 text-neutral-500 cursor-pointer">Framework Guide</p>

      <p class="title text-body1 text-primary-700 cursor-pointer">Components</p>
      <p class="sections text-body2 text-neutral-500 cursor-pointer">Color Picker</p>
      <p class="sections text-body2 text-neutral-500 cursor-pointer">Tooltip</p>

    </div>
  </div>
  <div class="grow">
    <div class="w-full px-4 py-6 border-b border-neutral-200">
      <p class="title text-body1 text-primary-700 cursor-pointer">Components</p>
    </div>
    <div class="py-6 px-16">
      <router-outlet/>
    </div>
  </div>
</div>
