<div class="flex flex-col gap-8">

  <div class="flex flex-col gap-5">
    <p class="text-h6">Color Picker</p>
    <div class="flex gap-20">
      <div class="flex flex-col gap-5">
        <p class="text-body1 text-primary-500">Color picker view</p>
        <app-color-picker></app-color-picker>
      </div>
      <div class="flex flex-col gap-5">
        <p class="text-body1 text-primary-500">Color picker tooltip</p>
        <p class="border h-min border-neutral-300 px-4 py-2 rounded cursor-pointer" appColorPicker>Tap to pick a color</p>
      </div>
    </div>
    <div class="border-b border-neutral-200"></div>
  </div>

  <p class="text-h6">Tooltip</p>

  <div class="inline-flex items-center justify-center w-min h-min gap-5">
    <p class="border h-min border-neutral-300 px-4 py-2 rounded cursor-pointer" tooltip="Information about it."
       [position]="TooltipPosition.LEFT">Left</p>
    <p class="border h-min border-neutral-300 px-4 py-2 rounded cursor-pointer" tooltip="Information about it."
       [position]="TooltipPosition.RIGHT">Right</p>
    <p class="border h-min border-neutral-300 px-4 py-2 rounded cursor-pointer" tooltip="Information about it."
       [position]="TooltipPosition.ABOVE">Above</p>
    <p class="border h-min border-neutral-300 px-4 py-2 rounded cursor-pointer" tooltip="Information about it."
       [position]="TooltipPosition.BELOW">Below</p>
<!--    <div class="flex justify-center items-center border-2 border-dotted border-neutral-500 rounded h-20 w-20" [tooltip]="coordinates"-->
<!--         [position]="TooltipPosition.DYNAMIC">Dynamic</div>-->
  </div>

</div>
