import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef, EmbeddedViewRef, EventEmitter,
  HostListener,
  inject, Injector, OnDestroy, Output,
  ViewContainerRef
} from '@angular/core';
import {ColorPickerComponent} from "./color-picker.component";
import {Subscription} from "rxjs";
import {TooltipComponent} from "../tooltip/tooltip.component";

@Directive({
  selector: '[appColorPicker]',
  standalone: true
})
export class ColorPickerDirective implements OnDestroy{

  @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>(false);

  private componentRef: ComponentRef<ColorPickerComponent> | null = null;

  elementRef = inject(ElementRef);
  viewContainerRef = inject(ViewContainerRef);
  componentFactoryResolver = inject(ComponentFactoryResolver);
  appRef = inject(ApplicationRef);
  injector = inject(Injector);

  overlay: any;

  @HostListener('click')
  onClick() {
    if (this.componentRef) {
      this.closeColorPicker();
      return;
    }

    this.initializeColorPicker();
  }

  private initializeColorPicker() {
    if (this.componentRef === null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ColorPickerComponent);
      this.componentRef = componentFactory.create(this.injector);

      this.appRef.attachView(this.componentRef.hostView);
      const [domElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;
      const elementRect = this.elementRef.nativeElement.getBoundingClientRect();

      this.overlay = document.createElement("div");
      this.overlay.id = "ng-color-picker";
      this.overlay.classList.add("ng-color-picker");
      this.overlay.style['position'] = 'fixed';
      this.overlay.style['height'] = '100%';
      this.overlay.style['width'] = '100%';
      this.overlay.style['z-index'] = 2000;
      this.overlay.style['top'] = '0';
      this.overlay.style['left'] = '0';


      this.componentRef.location.nativeElement.style.position = 'absolute';
      this.componentRef.location.nativeElement.style['z-index'] = 2001;
      this.componentRef.location.nativeElement.style.top = `${elementRect.bottom + 20}px`;
      this.componentRef.location.nativeElement.style.left = `${elementRect.left}px`;
      this.componentRef.instance.COLOR_PICKER_HEIGHT.set(140);
      this.componentRef.instance.COLOR_PICKER_WIDTH.set(250);
      this.componentRef.instance.isTooltip.set(true);

      this.componentRef.instance.colorChanged.subscribe((colorCode: string) => {
        this.colorChanged.emit(colorCode);
        this.closeColorPicker();
      });

      this.componentRef.instance.closeColorPicker.subscribe(() => {
        this.closeColorPicker();
      });

      document.body.appendChild(this.overlay);

      document.body.appendChild(domElement);
    }
  }

  // private initializeColorPicker() {
  //   if(this.componentRef === null) {
  //     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ColorPickerComponent);
  //     this.componentRef = componentFactory.create(this.injector);
  //
  //     this.appRef.attachView(this.componentRef.hostView);
  //     const [domElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;
  //
  //     this.setColorPickerProperties();
  //
  //     document.body.appendChild(domElement);
  //
  //   }
  // }
  //
  // private setColorPickerProperties() {
  //   if (this.componentRef !== null) {
  //     const elementRect = this.elementRef.nativeElement.getBoundingClientRect();
  //
  //
  //     this.componentRef.location.nativeElement.style.position = 'absolute';
  //     this.componentRef.location.nativeElement.style.top = `${elementRect.bottom + 20}px`;
  //     this.componentRef.location.nativeElement.style.left = `${elementRect.left}px`;
  //     this.componentRef.instance.COLOR_PICKER_HEIGHT.set(140);
  //     this.componentRef.instance.COLOR_PICKER_WIDTH.set(250);
  //     this.componentRef.instance.isTooltip.set(true);
  //
  //     this.componentRef.instance.colorChanged.subscribe((colorCode: string) => {
  //       this.colorChanged.emit(colorCode);
  //       this.closeColorPicker();
  //     });
  //
  //     this.componentRef.instance.closeColorPicker.subscribe(() => {
  //       this.closeColorPicker();
  //     });
  //   }
  // }

  closeColorPicker() {

    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.overlay.remove();
      this.componentRef = null;
    }
    // this.viewContainerRef.clear();
    // this.componentRef = null;
  }

  ngOnDestroy(): void {
    this.closeColorPicker();
  }

}
